.self-checkout {

  &-wrapper {
    width: 100%;
    height: calc(100vh - 84px);
    display: flex;
    flex: 1;
    align-items: flex-start;
    justify-content: center;
    background-color: $highlight-background-color;

    @media #{$mobile} {
      height: calc(100vh - 64px);
    }
  }

  &-container {
    background-color: white;
    width: 50%;
    margin: 3%;
    border-radius: 7px !important;
    border: 1px solid $box-border-color !important;
    padding: 20px;
    box-shadow: none !important;
    align-items: flex-end;
    display: flex;
    flex-direction: column;

    @media #{$mobile} {
      width: 100%;
    }
  }

  &-box {
    width: 100%;
    border-radius: 7px;
    padding: 15px;
    font-weight: 500;
    display: flex;
    align-items: center;

    &-info {
      background-color: $info-box;
      border: 1px solid $info-box-border;
      color: $info-box-text;
    }
    &-danger {
      background-color: $danger-box;
      border: 1px solid $danger-box-border;
      color: $danger-box-text;
    }

    &-image {
      width: 50px;
      margin-right: 10px;
    }

    &-separator {
      height: 10px;
    }
  }

  &-amount {
    &-container {
      width: 100%;
      display: flex;
      margin-top: 45px;
      align-items: flex-end;
      justify-content: flex-end;
      position: relative;
    }

    &-input {
      width: calc(40% + 10px);

      > div {
        margin: 0;
        height: 80px;
        display: flex;
        align-items: flex-end;

        &:before {
          border-bottom-color: $box-border-color;
          border-bottom-width: 2px;
        }
      }

      input {
        font-size: 65px;
        color: $medium-text;
        margin: 0;
        padding: 0;
      }

      @media #{$mobile} {
        width: calc(70% + 10px);
      }
    }

    &-currency {
      position: absolute;
      top: 15px;
      right: calc(40% + 10px);
      margin-right: 7px;
      font-size: 25px;
      color: $medium-text;

      @media #{$mobile} {
        right: calc(70% + 10px);
      }
    }
  }

  &-submit {
    padding: 7px 37px !important;
    margin-top: 25px !important;
    font-size: 16px !important;

    &-loading {
      width: 27px !important;
      height: 27px !important;
    }
  }

}