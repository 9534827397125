.mobile-pay-action-box {
  display: none;
  color: $light-txt-color;

  @media #{$mobile} {
    display: block;
    width: 90%;
    margin: 0 auto 0.5em auto;

    .MuiButton-root {
      margin-top: 1em;
    }
  }
}

.desktop-pay-action-box {
  display: block;

  p {
    margin-block-start: 1em !important;
  }

  @media #{$mobile} {
    display: none;
  }
}

.data-container {
  height: 600px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  flex-grow: 1;
  width: 100%;
  max-width: 1200px;

  .MuiDivider-root {
    @media #{$mobile} {
      display: none;
    }
  }

  @media #{$tablet} {
    max-width: 100%;
    box-sizing: border-box;
  }

  @media #{$mobile} {
    flex-direction: column;
    width: 100%;
    height: auto;
  }

  .pay-actions {
    button {
      @media #{$mobile} {
        margin: 0;
      }
    }

    .subtitle {
      margin-top: 24px;
    }

    @media #{$mobile} {
      margin-top: 1em;
    }
  }

  .reset-link {
    user-select: none;
    cursor: pointer;
  }

  .register-actions {
    margin: 1.8em 0;

    .register-link {
      margin: 1em 0;
      display: flex;
      justify-content: center;
      user-select: none;
      cursor: pointer;
    }

    @media #{$mobile} {
      margin: 0.8em 0;
    }
  }
}

.main-container {
  flex: 1;
  display: flex;
  justify-content: center;
  max-width: 100%;
  background-color: #fff;
  @media #{$mobile} {
    width: 100%;
    align-items: flex-start;
  }
}

.payment-details-container {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  box-sizing: border-box;
  background-color: #f6f6f6;

  @media #{$mobile} {
    width: 100%;
    flex: none;
    box-shadow: 0 0 7px 2px rgba(0, 0, 0, 0.1);
    margin: 0;
    z-index: 100;
    position: absolute;
    top: 64px;
    background-color: transparent;
    transition: all 0.2s linear;
  }

  &-open {
    @media #{$mobile} {
      background-color: rgba(0,0,0,0.4);
      bottom: 0;
    }
  }
}

.confirm-cat-container {
  width: 500px;
  color: $light-txt-color;
  margin-right: 50px;

  h1 {
    color: $light-txt-color;
    font-size: 20px;
    line-height: 18px;
    font-weight: 400;
  }

  p {
    margin-block-start: 0;
  }

  .welcome {
    @media #{$mobile} {
      margin-top: 16px;
    }
  }

  #details-heading {
    color: $primary-color;
    font-size: 16px;
    margin-block-end: 0.7em;
  }

  @media #{$tablet} {
    width: 450px;
    max-width: 45%;
    margin-right: 30px;
  }

  @media #{$mobile} {
    width: 90%;
    margin: 0 auto;
  }
}

.register-container,
.reset-password-container {
  @extend .confirm-cat-container;
  justify-content: center;
  margin: 0 auto;
  padding: 32px 0;

  .MuiTextField-root {
    margin-bottom: 0.5em;
  }
  .MuiTextField-root:last-of-type {
    margin-bottom: 0;
  }
  .passwords-div {
    margin-top: 1.5em;
  }

  @media #{$tablet} {
    width: 450px;
    max-width: 45%;
  }

  @media #{$mobile} {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;
    padding: 32px 16px;
    margin: 0 auto;
  }
}

.confirm-card-container {
  color: $dark-txt-color;
  display: flex;
  align-items: flex-start;
  margin-bottom: 35px;
  p {
    margin-block-start: 0;
    margin-block-end: 0;
  }

  @media #{$mobile} {
    margin-bottom: 0;
  }
}

.screen-feedback-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
  display: none;
  @media #{$mobile} {
    margin: 15px;
  }
}

.iframe-container {
  flex: 2;
  display: flex;
  justify-content: flex-end;
  margin-right: 0;
  overflow: hidden;
  margin-top: 30px;

  iframe {
    width: 100%;
    height: 100%;
  }

  @media #{$mobile} {
    width: 100%;
    margin: 105px 0 0;
    border-radius: 0;
    border: none;
    flex: 3;
  }
}

.user-details-container {
  @extend .confirm-cat-container;
  width: 950px;

  @media #{$mobile} {
    padding: 24px 16px;
    flex-grow: 1;

    .MuiGrid-spacing-xs-2 {
      margin-top: 8px;

      .MuiGrid-item {
        padding-top: 0;
        padding-bottom: 0;
      }
    }
  }
}

.wide {
  max-width: 100%;
}
