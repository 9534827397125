// $success-color: #29c905;
// $error-color: #b54444;
// $primary-bg-color: #e2e2e2;
$primary-contrast-color: #fff;

$primary-color: #6e28fa;
$success-color: #333;
$error-color: #333;

$primary-bg-color: #f6f6f6;
$primary-bg-box-color: #fff;
$box-border-color: #dedddc;
$highlight-background-color: #f3f3f3;
$highlight-line-color: #e6e6e6;
$separating-line:#e6e6e6;
$general-text-color: #666;
$title-text-color:#333;
$medium-text: #666666;

$dark-txt-color: #5d5d5d;
$light-txt-color: #aeaeae;
$secondary-txt-color: #bb9aff;
$link-color: #0077ff;
$hover-bg-color: #bb9aff26;
$info-box: #cce5ff;
$info-box-border: #b8dbff;
$info-box-text: #004185;
$danger-box: #f8d7da;
$danger-box-border: #f5c6cb;
$danger-box-text: #721c24;

$mobile: "only screen and (max-width: 768px)";
$tablet: "only screen and (max-width: 992px) and (min-width: 769px)";
$desktop: "only screen and (min-width: 769px)";
