.feedback-color-success {
  background-color: $highlight-background-color;
  color: $success-color;
  padding: 15px 10px 10px 20px;

  // &:before {
  //   background-color: $primary-color;
  // }
}

.feedback-color-failed {
  background-color: $highlight-background-color;
  color: $error-color;
  padding: 15px 10px 10px 20px;

  // &:before {
  //   background-color: $error-color;
  // }
}

.main-feedback-container {
  width: 700px;
  height: auto;
  text-align: justify;
  border: 1px solid $highlight-line-color;
  border-radius: 7px;
  overflow: hidden;
  background-color: #fff;
  margin-top: 32px;

  @media #{$mobile} {
    width: 100%;
    padding: 0;
    justify-content: flex-start;
    display: flex;
    flex-direction: column;
    background-color: $primary-bg-box-color;
    margin-top: 0;
  }

  .top-feedback-box {
    height: fit-content;
    position: relative;

    border-radius: 6px;
    border: 1px solid $separating-line;

    margin: 20px;

    display: flex;
    align-items: center;

    // &:before {
    //   height: 240px;
    //   width: 240px;
    //   border-radius: 120px;
    //   position: absolute;
    //   top: -60px;
    //   margin-left: auto;
    //   margin-right: auto;
    //   left: 0;
    //   right: 0;
    //   z-index: -1;
    //   content: "";
    // }

    @media #{$mobile} {
      height: auto;
      width: auto;
      justify-content: flex-start;
      margin: 13px;
    }

    .feedback-header{
      margin-left: 20px;
    }

    .feedback-header-title {
      z-index: 1;
      font-size: 18px;
      margin-block-start: 0.9em;
      margin-block-end: 0;
    }

    .feedback-header-second-title {
      z-index: 1;
      font-size: 18px;
      margin-block-start: 0.1em;
      margin-block-end: 0;
    }

    .feedback-header-subtitle {
      margin-block-start: 0;
      margin-top: 10px;
    }
  }

  .bottom-feedback-box {
    background-color: $primary-bg-box-color;
    margin-bottom: 0;
    padding: 0 30px 0;
    color: $general-text-color;

    @media #{$mobile} {
      padding: 5px;
      padding-top: 0px;
      margin-bottom: 0;
      border: none;
    }

    p {
      text-align: left;
      margin-block-start: 0;
      margin: 8px;
    }

    img {
      max-height: 80px;
      max-width: 300px;
    }

    .MuiButton-root {
      margin-top: 1em;
    }

    .feedback-footer {
      .MuiDivider-root {
        margin: 2em 0;
      }
      p {
        text-align: center;
      }
    }

    .feedback-actions {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      border-top: 1px solid $box-border-color;
      padding: 10px 0;
      margin-top: 10px;

      & > button {
        height: 33px;
        min-width: 100px;
        width: auto;
        padding: 0 15px;
        background-color: $primary-color;
        color: $primary-contrast-color;
        border: none;
        border-radius: 3px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0.8rem;
        cursor: pointer;

        &:hover {
          background-color: darken($primary-color, 5);
        }

        &:last-child {
          margin-left: 10px;
        }

        & > svg {
          margin-left: 5px;
          width: 15px;
          height: 15px;
        }
      }
    }

    .title{
      font-size: 14px;
      color: $title-text-color;

      @media #{$mobile} {
        margin-top: 0px;
      }
    }

    .code {
      display: flex;
      flex-direction: column;
      font-size: 12px;
    }

    .bottom-values{
      margin-left: 18px;

      @media #{$mobile} {
        margin-left: 0;
      }
    }
  }

  .feedback-logo {
    @media #{$mobile} {
      margin-bottom: 1em;
    }
  }

  .products{
    border-top: 1px solid $separating-line;
    padding: 5px;
    margin-bottom: 0px;
    padding-bottom: 0px;
    margin-left: 13px;

    .line{
       margin: 10px;
    }

    @media #{$mobile} {
      border-top: none;
      padding: 0;
      margin-left: 0;
    }
  }

  .totalAmount{
    background-color: $highlight-background-color;
    text-align: right;
    margin: auto;
    padding: 10px;
    border: solid $highlight-line-color;
    border-width: 1px 0px 1px 0px;

    @media #{$mobile} {
      border: none;
    }
  }
}
