.toolbar {
  padding: 24px 40px;

  @media #{$mobile} {
    padding: 14px;
  }

  .toolbar-items {
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .slogan {
    color: #bb9aff;
    margin: 0 26px;
    @media #{$mobile} {
      display: none;
    }
  }

  .toolbar-logo {
    cursor: pointer;
    user-select: none;

    @media #{$mobile} {
      width: 70%;
    }
  }

  .cancel {
    cursor: pointer;
    user-select: none;
  }

  p {
    margin-block-start: 0;
    margin-block-end: 0;
  }

  div {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}
