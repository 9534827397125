.payment-details {
  border-radius: 15px;
  border: 1px solid $primary-bg-color;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  max-width: 400px;
  box-sizing: border-box;

  @media #{$mobile} {
    border-radius: 0;
    border-width: 1px 0 1px 0;
    width: 100%;
    max-width: 100%;
    background-color: #f6f6f6;
    height: fit-content;
  }

  .products {
    text-decoration: dashed;
  }

  .product {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 4px;
    border-bottom: 1px solid $primary-bg-color;
    padding: 2px 0;
  }
  .top {
    padding: 30px;
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;

    @media #{$mobile} {
      padding: 20px 40px;
    }
  }

  .bottom {
    margin-bottom: 16px;
    padding: 30px;
    padding-top: 0;
    margin-top: 30px;

    @media #{$mobile} {
      margin-bottom: 0;
      padding: 10px;
      margin-top: 0;
      border-top: 1px solid rgba(0, 0, 0, 0.12);
    }
  }
}

.details-screen {
  @extend .confirm-cat-container;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  flex-grow: 1 1;
  justify-content: center;
  width: 100%;
  min-width: 100%;

  @media #{$mobile} {
    padding: 0;
    height: calc(100vh - 84px);
  }
}

.payment-details-screen {
  .data-container {
    @media #{$mobile} {
      flex-direction: column-reverse !important;
      height: calc(100vh - 44px);
    }
  }
}