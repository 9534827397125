.login-container {
  width: 500px;
  padding: 24px 16px;
  margin-right: 50px;
  
  @media #{$tablet} {
    width: 450px;
    max-width: 45%;
    margin-right: 30px;
  }

  @media #{$mobile} {
    width: 100%;
    margin: 0 auto;
  }
  
  p {
    margin-bottom: 24px;
  }

  .MuiInputBase-root:first-of-type {
    margin-bottom: 1em;
  }
}
