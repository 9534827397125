.payment-history-container,
.payment-detail-container {
  @extend .confirm-cat-container;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  flex-grow: 1 1;
  justify-content: center;
  width: 800px;
  max-width: 100%;
  padding: 64px 0;

  @media #{$mobile} {
    padding: 24px 16px 0 16px;
  }

  .history-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .MuiInputBase-root {
      margin-block-end: 0;
      margin: 0.5em 0;
    }

    .MuiSelect-outlined {
      padding-top: 12px;
      padding-bottom: 12px;
    }
  }

  .table,
  .table-head {
    margin-top: 16px;

    .row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: 16px;
      border-bottom: 1px solid $primary-bg-color;

      &:hover {
        cursor: pointer;
        background-color: $hover-bg-color;
      }
      span {
        font-size: 16px;
        line-height: 14px;
      }

      .mark {
        width: 55%;

        @media #{$mobile} {
          width: 60%;
        }
      }

      .date {
        width: 20%;

        @media #{$mobile} {
          display: none;
        }
      }

      .amount {
        width: 20%;
        text-align: end;
        color: $link-color;

        @media #{$mobile} {
          width: 30%;
        }
      }

      .arrow {
        width: 5%;
        text-align: end;
        color: $link-color;
        cursor: pointer;
        user-select: none;

        @media #{$mobile} {
          width: 10%;
        }
      }
    }
  }

  .table-head {
    color: $dark-txt-color;

    .row {
      border-bottom: none;
      padding-bottom: 0;

      .amount {
        color: $dark-txt-color;
      }
    }
  }

  .payment-detail {
    margin-top: 16px;

    .details-chunk {
      padding: 24px;
      border-right: 1px solid $primary-bg-color;

      @media #{$mobile} {
        padding: 24px 16px;
        border-right: none;
        border-bottom: 1px solid $primary-bg-color;
      }

      img {
        height: 50px;
        width: auto;
        margin: 8px 0;
      }

      .chunk {
        display: flex;
        flex-direction: column;

        p {
          color: $dark-txt-color;
          margin: 0;
        }

        .title {
          color: $light-txt-color;
          margin-top: 16px;
        }
      }
    }

    .right-chunk {
      display: flex;
      flex-direction: column;

      .payment-method-chunk {
        padding: 24px 36px;
        border-bottom: 1px solid $primary-bg-color;
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        @media #{$mobile} {
          padding: 24px 16px;
          align-items: flex-start;
        }

        img {
          height: 30px;
          width: auto;
          margin: 16px 0;
        }

        p {
          margin: 0;
        }

        b {
          color: $dark-txt-color;
        }
      }

      .totals-chunk {
        padding: 24px;
        padding-top: 48px;
        display: flex;
        flex-direction: column;
        align-items: center;

        @media #{$mobile} {
          padding: 24px 16px;
        }

        .line {
          width: 80%;
          padding-top: 8px;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;

          @media #{$mobile} {
            width: 100%;

            span:first-of-type {
              text-align: start;
            }
          }

          span {
            text-align: end;
            margin-right: 12px;
          }

          .dark-txt {
            width: 60%;
            color: $dark-txt-color;
          }

          .currency {
            color: $light-txt-color;
            margin-right: 4px;
          }

          .total {
            font-weight: bold;
            font-size: 20px;
            margin-right: 0;
          }
        }
      }
    }
  }
}
