@import "./variables";
@import "./payment";
@import "./historic";
@import "./linkError";
@import "./loading";
@import "./login";
@import "./paymentDetails";
@import "./paymentResult";
@import "./feedbackBox";
@import "./toolbar";
@import "./selfCheckout";
@import url("https://use.typekit.net/bak5nfs.css");

body {
  margin: 0;
  font-family: "muli", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

html,
body,
#root {
  min-height: 100vh;
}

.page {
  min-height: 100vh;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}
